import { Mois } from './mois/Mois';
import { useEffect, useState } from 'react';
import { TypeVisualisation } from '../../../enums/type-visualisation.enum';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faColumns, faFilter, faPencilAlt } from '@fortawesome/free-solid-svg-icons';

import './Presences.scss';
import './Presences.mobile.scss';
import { Semaine } from './semaine/Semaine';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { SelectMonth } from '../shared/select-month/SelectMonth';
import { SelectWeek } from '../shared/select-week/SelectWeek';
import { PresenceDto } from '../../../../models/presence.dto';
import { PresencesFiltersModel } from '../../../models/presences-filters.model';
import { PresencesFilters } from './presences-filters/PresencesFilters';
import { JourMobile } from './jour/Jour.mobile';
import { useMobileDetect } from '../../../hooks/useMobileDetect';
import { useAppSelector } from '../../../redux/hooks';
import { getDaysInMonth, getDaysInWeek } from '../../../helpers/date-helpers';
import { Loader } from '../shared/loading/Loader';
import presenceService from '../../../services/presence.service';

export const Presences = () => {
  const [typeVisualisation, setTypeVisualisation] = useState(TypeVisualisation.Semaine);
  const [day, setDay] = useState(new Date());
  const [dayWatched, setDayWatched] = useState<Date>(new Date());
  const [presences, setPresences] = useState<PresenceDto[]>([]);
  const [presencesFilter, setPresencesFilter] = useState<PresencesFiltersModel>({
    users: [],
  });
  const [openFilters, setOpenFilters] = useState(false);
  const isMobile = useMobileDetect();
  const [isLoading, setIsLoading] = useState(false)

  const agenceName = useAppSelector(state => state.agence.selectedAgence?.name)
  const selectedArea = useAppSelector(state => state.agence.selectedArea);

  useEffect(() => {
    const days = typeVisualisation === TypeVisualisation.Mois
      ? getDaysInMonth(day)
      : getDaysInWeek(day);

    setIsLoading(true);

    if (selectedArea) {
      presenceService.getPresencesInRange(
        selectedArea.guid,
        days[0],
        days[days.length - 1])
        .then((presences) => {
          setPresences(presences);
          setIsLoading(false);
        });
    }
  }, [typeVisualisation, day, selectedArea]);

  const filteredPresences = presences.filter(presence => {

    let valid = true;

    if (valid && presencesFilter.users.length > 0) {
      valid = presencesFilter.users.includes(presence.user?.guid as string);
    }

    if (valid && presencesFilter.groupe && presence.user?.groups) {
      valid = presence.user?.groups?.includes(presencesFilter.groupe);
    }
    return valid;
  });

  const { t } = useTranslation();

  const typeVisualisationSelector = (
    <div className="type-visualisation-selector">
      <button className={'btn ' + (typeVisualisation === TypeVisualisation.Mois ? 'btn-primary' : 'btn-tertiary')}
        onClick={() => setTypeVisualisation(TypeVisualisation.Mois)}>
        <FontAwesomeIcon icon={faCalendarAlt}></FontAwesomeIcon>
      </button>
      <button className={'btn ' + (typeVisualisation === TypeVisualisation.Semaine ? 'btn-primary' : 'btn-tertiary')}
        onClick={() => setTypeVisualisation(TypeVisualisation.Semaine)}>
        <FontAwesomeIcon icon={faColumns}></FontAwesomeIcon>
      </button>
    </div>
  );

  return (
    <div className="presences container">
      <div className="top-content">
        {(typeVisualisation === TypeVisualisation.Mois) &&
          <SelectMonth
            setDayWatched={setDayWatched}
            mois={day}
            setMois={setDay} />}
        {(typeVisualisation === TypeVisualisation.Semaine) &&
          <SelectWeek
            day={day}
            setDay={setDay} />}

        <div className='center'>
          <h1>{agenceName}</h1>
          <h2>{selectedArea?.name}</h2>
          <button className="btn btn-tertiary"
            onClick={() => setOpenFilters(true)}>{t('global.change')}</button>
        </div>

        <div className="right">
          {!isMobile && (
            <Link to="/saisie" className="btn btn-tertiary btn-icon">
              <FontAwesomeIcon icon={faPencilAlt}></FontAwesomeIcon>{" "}
              {t("presences.saisirPresences")}
            </Link>
          )}
          {typeVisualisationSelector}
          <button className="btn btn-tertiary btn-filters" onClick={() => setOpenFilters(!openFilters)}>
            <FontAwesomeIcon icon={faFilter}></FontAwesomeIcon>
          </button>
        </div>
      </div>

      <div>
        {isLoading ? <Loader /> :
          <>
            <div className="presences-content">
              {(typeVisualisation === TypeVisualisation.Mois) &&
                <Mois
                  setDayWatched={setDayWatched}
                  dayWatched={dayWatched}
                  mois={day}
                  presences={filteredPresences}
                  setTypeVisualisation={setTypeVisualisation}
                  setDay={setDay} />}
              {(typeVisualisation === TypeVisualisation.Semaine) &&
                <Semaine
                  day={day}
                  presences={filteredPresences} />}
            </div>
            {/* Affichage du jour en dessous du calendrier pour les mobiles */}
            {isMobile && typeVisualisation === TypeVisualisation.Mois &&
              <JourMobile
                day={dayWatched}
                presences={filteredPresences} />
            }
          </>
        }

        <PresencesFilters
          open={openFilters}
          setOpen={setOpenFilters}
          filters={presencesFilter}
          setFilters={setPresencesFilter}
          presences={presences}
        />
      </div>
    </div>
  );
};
