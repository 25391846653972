import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { getPreviousMonday } from '../../../../helpers/date-helpers';
import './SelectWeek.scss';

export type SelectWeekProps = {
  day: Date;
  setDay?: (p: Date) => void;
}

export const SelectWeek = ({ day, setDay }: SelectWeekProps) => {
  const { t } = useTranslation();
  const lundi = getPreviousMonday(day);

  const weekString = t('presences.semaineDu') + lundi.toLocaleString('default', { day: 'numeric', month: 'long', year: 'numeric' });

  const clickArrow = (weekIncrement: number) => {
    if (setDay) {
      const newLundi = new Date(lundi);
      newLundi.setDate(newLundi.getDate() + (weekIncrement * 7));
      setDay(newLundi);
    }
  }

  const clickMonth = () => {
    setDay?.(getPreviousMonday(new Date()));
  }

  return (
    <div className="select-week">
      {setDay &&
        <button className="btn btn-tertiary" onClick={() => clickArrow(-1)}>
          <FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon>
        </button>
      }

      <button className="btn btn-tertiary week" onClick={() => clickMonth()}>{weekString}</button>

      {setDay &&
        <button className="btn btn-tertiary" onClick={() => clickArrow(1)}>
          <FontAwesomeIcon icon={faArrowRight}></FontAwesomeIcon>
        </button>
      }
    </div>
  )
};
