import { AxiosResponse } from 'axios';
import { LoginDto } from '../../models/login.dto';
import { UserDto } from '../../models/user.dto';
import { httpClient } from '../config/axios';

const userService = {
  async getOrCreateUser(user: UserDto): Promise<UserDto> {
    const response = await httpClient.post<UserDto>('/users', user);
    return response.data;
  },

  async getCurrentUser(): Promise<UserDto> {
    const response = await httpClient.get<UserDto>('/users/current');
    return response.data;
  },

  async createUser(user: UserDto): Promise<LoginDto>{
    const response = await httpClient.post<UserDto,AxiosResponse<LoginDto>>('/users/login', user);
    return response.data;
  }
};

export default userService;
