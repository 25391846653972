import { ReactNode, useCallback, useState } from 'react'
import './Tooltip.scss';

export type TooltipProps = {
  children: ReactNode;
  className?: string;
  [prop: string]: any;
};

export const Tooltip = ({children, className, ...props}: TooltipProps) => {
  const [horizontalPosition, setHorizontalPosition] = useState('right');
  const [verticalPosition, setVerticalPosition] = useState('bottom');
  const maxTooltipWidth = 200;
  const maxTooltipHeight = 80;

  const tooltipContainerRef = useCallback((tooltipContainer: HTMLDivElement) => {
    if (!tooltipContainer) {
      return;
    }

    const containerRect = tooltipContainer.getClientRects()[0];
    const hOffset = window.innerWidth - containerRect.right;
    if (hOffset < (maxTooltipWidth + 10)) {
      setHorizontalPosition('left');
    }
    const vOffset = window.innerHeight - containerRect.bottom;
    if (vOffset < (maxTooltipHeight + 10)) {
      setVerticalPosition('top');
    }
  }, []);

  let cssClasses = `tooltip-container ${horizontalPosition} ${verticalPosition}`;
  if (className) {
    cssClasses += ` ${className}`;
  }

  return (
    <div {...props} className={cssClasses} ref={tooltipContainerRef}>
      {children}
    </div>
  );
}
