import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { AreaDto } from '../../../../../../models/area.dto';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import { agenceActions } from '../../../../../redux/agence-slice';

export const AreaFilter = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation()
  const areas = useAppSelector(state => state.agence.selectedAgence?.areas);
  const selectedArea = useAppSelector(state => state.agence.selectedArea);

  return (
    <div>
      <h3>{t('presences.filtres.area')}</h3>
      {areas &&
        <Select
          options={areas}
          onChange={area => dispatch(agenceActions.selectArea(area as AreaDto))}
          value={selectedArea}
          getOptionLabel={u => `${u.name} (${u.maxAvailableSlots} places max)`}
          getOptionValue={u => u.guid}
        />
      }
    </div>
  )
}
