import { ReactNode } from 'react';
import { Portal } from 'react-portal';
import './SlidePanel.scss';

export type SlidePanelProps = {
  children: ReactNode;
  open: boolean;
  setOpen: (open: boolean) => void;
};

export const SlidePanel = ({ children, open, setOpen }: SlidePanelProps) => {
  return (
    <Portal>
      <div className={'slide-panel-container ' + (open ? 'open' : 'close')}>
        <div className="overlay" onClick={() => setOpen(false)}></div>
        <div className="slide-panel">
          {children}
        </div>
      </div>
    </Portal>
  )
}
