
import { useMemo } from 'react';
import { PresenceDto } from '../../../../../models/presence.dto';
import { TypeVisualisation } from '../../../../enums/type-visualisation.enum';
import { daysOfWeek, getDayName, getDaysInMonth } from '../../../../helpers/date-helpers';
import { useMobileDetect } from '../../../../hooks/useMobileDetect';
import { PaddingDays } from '../../saisie/saisie-mois/days/PaddingDays';
import './Mois.scss';
import { JourMois } from './jour-mois/JourMois';

export type MoisProps = {
  mois: Date;
  presences: PresenceDto[];
  setDayWatched: (date: Date) => void;
  dayWatched: Date;
  setTypeVisualisation: (typeVisualisation: TypeVisualisation) => void;
  setDay: (day: Date) => void;
};

export const Mois = ({ mois, presences, dayWatched, setDayWatched, setTypeVisualisation, setDay }: MoisProps) => {
  const isMobile = useMobileDetect();
  const daysInMonth = useMemo(() => getDaysInMonth(mois), [mois]);
  const nbPaddingDays = daysInMonth[0].getDay() - daysOfWeek[0];


  const onClickOnDay = (date: Date) => {
    setDayWatched(date);

    if (!isMobile) {
      // setDay permet à l'application de savoir sur quelle semaine regarder lorsque l'on passe de la vue mois à la vue semaine
      setDay(date);
      setTypeVisualisation(TypeVisualisation.Semaine);
    }
  }

  const showDays = daysInMonth.map(day =>
    <JourMois
      dayWatched={dayWatched}
      key={day.getTime()}
      date={day}
      presences={presences}
      onClickOnDay={onClickOnDay} />
  );

  const dayNames = daysOfWeek.map(d => {
      let dayName = getDayName(d, daysInMonth);
      if (isMobile) {
        dayName = dayName?.charAt(0);
      }
      return (<span className="day-name" key={'weekDay-' + d}>{dayName}</span>);
    });

  return (
    <div className="month">
      <div className="calendar-container">
        <div className="calendar">
          <div className="day-names">
            {dayNames}
          </div>
          <div className="days">
            <PaddingDays count={nbPaddingDays} />
            {showDays}
          </div>
        </div>
      </div>
    </div>
  );
};
