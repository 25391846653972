import axios, { AxiosError } from 'axios';
import { store } from '../redux/store';
import { toast } from 'react-toastify';
import i18n from './i18next';
import { setToken, setUser } from '../redux/user-reducer';

const httpClient = axios.create({
  baseURL: '/api/',
});

httpClient.interceptors.request.use(config => {
  let token = store.getState()?.user?.token;

  if (token) {
    config.headers = {
      ...(config.headers ?? {}),
      authorization: `Bearer ${token}`,
    };
  }

  return config;
});

httpClient.interceptors.response.use(response => response, (err: Error | AxiosError) => {
  if (axios.isAxiosError(err)) {
    const axiosError = err as AxiosError;
    switch (axiosError.response?.status) {
      case 401:
        localStorage.removeItem('token');
        store.dispatch(setUser(null));
        store.dispatch(setToken(null));
        const msgDisconnected = i18n.t('errors.disconnected')
        i18n.loadResources(() => toast.error(msgDisconnected))
        break;
      case 500:
        const msgError = i18n.t('errors.serverError')
        i18n.loadResources(() => toast.error(msgError));
        break;
    }
  }
  return Promise.reject(err);
});

export {
  httpClient,
};
