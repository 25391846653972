import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { persistor, store } from './redux/store';
import { PersistGate } from 'redux-persist/integration/react';
import { Login } from './modules/login/Login';
import { PrivateRoute } from './modules/shared/PrivateRoute';
import { Suspense } from 'react';
import { Header } from './modules/shared/header/Header';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './App.scss';
import { Saisie } from './modules/calendar/saisie/Saisie';
import { Presences } from './modules/calendar/presences/Presences';
import { LoginTeam } from './modules/login/loginTeam';


const App = () => {
  return (
    <div className="app">
      <ToastContainer></ToastContainer>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <Suspense fallback="loading">

            <Header></Header>

            <BrowserRouter>
              <Switch>
                <Route path="/login">
                  <Login></Login>
                </Route>
                <Route path="/loginTeam">
                  <LoginTeam></LoginTeam>
                </Route>

                <PrivateRoute path="/saisie">
                  <Saisie></Saisie>
                </PrivateRoute>

                <PrivateRoute path="/">
                  <Presences></Presences>
                </PrivateRoute>
              </Switch>
            </BrowserRouter>
          </Suspense>
        </PersistGate>
      </Provider>
    </div>
  );
};

export default App;
