import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserDto } from '../../models/user.dto';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist'
import { addHours } from '../helpers/date-helpers';
import { fetchApolloAgencesAction } from './agence-slice';

export interface UserState {
  user: UserDto | null;
  token: string | null;
  currentUserExpirationDate: number | null;
}

const initialState: UserState = {
  user: null,
  token: null,
  currentUserExpirationDate: null
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<UserDto | null>) => {
      state.user = action.payload;
      state.currentUserExpirationDate = (addHours(new Date(), 1))
    },
    setToken: (state, action: PayloadAction<string | null>) => {
      state.token = action.payload;
    },
    logout: (state) => {
      state.token = null;
      state.user = null;
    },
  }
});

export const setUser = createAsyncThunk("user/setUser", async (param: UserDto | null, { dispatch }) => {
  await dispatch(userSlice.actions.setUser(param))

  if (param) {
    await dispatch(fetchApolloAgencesAction())
  }

  return param;
})

export const { setToken, logout } = userSlice.actions;

const persistConfig = {
  key: 'root',
  storage
};

export default persistReducer(persistConfig, userSlice.reducer);
