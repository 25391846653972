import { PresenceDto } from '../../../../../models/presence.dto';
import { JourSemaine } from '../semaine/jour-semaine/JourSemaine';
import { presencesOfDay } from '../utils/presenceDtoUtils';
import './Jour.mobile.scss';

export type JourMobileProps = {
    day: Date;
    presences: PresenceDto[];
};

export const JourMobile = ({ presences, day }: JourMobileProps) => {

    return (
        <div className="jour-mobile">
            <JourSemaine key={day.getDate()} day={day} presences={presencesOfDay(presences, day)}></JourSemaine>
        </div>
    );
};
