import { AgenceDto } from '../../models/agence.dto';
import { httpClient } from '../config/axios';

const agenceService = {
    async getAllAgences(): Promise<AgenceDto[]> {
        const response = await httpClient.get<AgenceDto[]>('/agences');
        return response.data;
    }
};

export default agenceService;
