import cn from "classnames";
import { ReactNode } from "react";
import styles from "./buttonGroup.module.scss";

export interface ButtonGroupButton<T extends string | number | boolean> {
    value: T;
    children: ReactNode
}
interface ButtonGroupProps<T extends string | number | boolean> {
    buttons: ButtonGroupButton<T>[],
    selectedValue: T | null | undefined,
    onSelected: (value: T) => void
}

type ButtonGroupType = <T extends string | number | boolean>(p: ButtonGroupProps<T>) => React.ReactElement<ButtonGroupProps<T>>;

export const ButtonGroup: ButtonGroupType = ({ buttons, selectedValue, onSelected }) => {
    return (
        <div className={styles["options"]}>
            {buttons.map(b => {
                const classNames = cn('btn', styles['btn'], { 'btn-primary': selectedValue === b.value })

                return <button
                    key={b.value.toString()}
                    className={classNames}
                    onClick={() => onSelected(b.value)}>{b.children}</button>
            })}
        </div>
    )
}


