import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { PresenceDto } from '../../../../models/presence.dto';
import presenceService from '../../../services/presence.service';
import { PresenceType } from './presence-type/PresenceType';
import { SaisieMois } from './saisie-mois/SaisieMois';
import './Saisie.scss';
import './Saisie.mobile.scss';
import { DeletePresence } from './delete/Delete';
import { SaisieMoisMobile } from './saisie-mois-mobile/SaisieMois.mobile';
import { useMobileDetect } from '../../../hooks/useMobileDetect';
import { toast } from 'react-toastify';
import { EditionParameter } from './EditionParameter';
import { useAppSelector } from '../../../redux/hooks';

export const Saisie = () => {
  const { t } = useTranslation();
  const [presencesMois, setPresencesMois] = useState<PresenceDto[]>([]);
  const selectedAgence = useAppSelector((s) => s.agence.selectedAgence?.guid);
  const selectedArea = useAppSelector((s) => s.agence.selectedArea?.guid);
  const [editionParameter, setEditionParameter] = useState<EditionParameter>({
    agenceId: selectedAgence,
    areaId: selectedArea,
  });
  const [isDeleteMode, setDeleteMode] = useState(false);
  const [mois, setMois] = useState<Date>(new Date());
  const [loading, setLoading] = useState<boolean>(false);
  const [dayWatched, setDayWatched] = useState<Date>(new Date());
  const isMobile = useMobileDetect();



  useEffect(() => {
    setPresencesMois([]);
    setLoading(true);
    presenceService.getPresences(mois.getFullYear(), mois.getMonth() + 1).then(presences => {
      setLoading(false);
      setPresencesMois(presences);
    });
  }, [mois]);

  const updatePresencesMois = (presences: PresenceDto[]) => {
    setPresencesMois(presences);
    presenceService.setPresences(presences, mois.getFullYear(), mois.getMonth() + 1).then(
      () => toast.success(t(`saisie.enregistrement`)),
      err => {
        console.error(err);
        toast.error(t(`saisie.erreurEnregistrement`))
      }
    );
  };

  // Todo reprendre la vue mobile
  const saisieMois = isMobile
    // ? <SaisieMoisMobile setMois={setMois} mois={mois} setDayWatched={setDayWatched} dayWatched={dayWatched} setPresenceType={setPresenceType} presenceType={presenceType} setPresences={updatePresencesMois} presences={presencesMois} isMobile={isMobile} />
    ? <SaisieMois isDeleteMode={isDeleteMode} editionParameter={editionParameter} presencesMois={presencesMois} setPresencesMois={updatePresencesMois} mois={mois} setMois={setMois} loading={loading} />
    : <SaisieMois isDeleteMode={isDeleteMode} editionParameter={editionParameter} presencesMois={presencesMois} setPresencesMois={updatePresencesMois} mois={mois} setMois={setMois} loading={loading} />;

  const onDeleteModeToggled = () => {
    setDeleteMode(s => !s);
    setEditionParameter(ed => ({ agenceId: ed.agenceId, areaId: ed.areaId }));
  }

  const onEditionParameterChanged = (editionParameter: EditionParameter) => {
    setDeleteMode(false)
    setEditionParameter(editionParameter);
  }

  return (
    <div className="saisie container">
      <Link to="/" className="btn btn-tertiary btn-icon btn-back">
        <FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon>
        {t('saisie.backButton')}
      </Link>

      <div className="saisie-content">
        {!isMobile && <div className="mode-create-delete">
          {!isDeleteMode && (
            <PresenceType
              editionParameter={editionParameter}
              onSetEditionParameter={onEditionParameterChanged}
            />
          )}
          <DeletePresence onDeleteModeToggled={onDeleteModeToggled} />
        </div>}

        {saisieMois}
      </div>
    </div>
  );
}
