import { LieuPresence } from '../../../enums/lieu-presence.enum';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faBuilding, faUserTie, faHome, faBookOpen } from '@fortawesome/free-solid-svg-icons';

export type PresenceIconProps = {
  lieuPresence: LieuPresence | undefined,
};

export const PresenceIcon = ({ lieuPresence }: PresenceIconProps) => {
  let icon: IconDefinition | null = null;
  switch (lieuPresence) {
    case LieuPresence.Agence:
      icon = faBuilding;
      break;
    case LieuPresence.Client:
      icon = faUserTie;
      break;
    case LieuPresence.Teletravail:
      icon = faHome;
      break;
    case LieuPresence.Formation:
      icon = faBookOpen;
      break;
  }

  return icon ? <FontAwesomeIcon icon={icon}></FontAwesomeIcon> : null;
}
