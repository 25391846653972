import { FC } from 'react'
import { PresenceDto } from '../../../../../../models/presence.dto'
import { checkFerie } from '../../../../../helpers/date-helpers';
import styles from "./Day.module.scss";
import { DemiJournee } from '../../../../../enums/demi-journee.enum';
import { getPresenceForHalfDay, isEmptyHalfDay } from '../../../presences/utils/presenceDtoUtils';
import { PresenceIcon } from '../../../shared/PresenceIcon';
import { AbsenceIcon } from '../../../shared/AbsenceIcon';
import classNames from 'classnames';
import { useAppSelector } from '../../../../../redux/hooks';

const FerieDay: FC<{ day: Date }> = ({ day }) => {
    return <div className="day">
        <div className="date-number">{day.getDate()}</div>
        <div className={styles["jour-ferie"]}></div>
    </div>;
}

interface DayProps {
    day: Date;
    presencesMois: PresenceDto[];

    canClick: boolean;
    onClickMorning: () => void;
    onClickAfternoon: () => void;
    onClickFullDay: () => void;
}

export const Day: FC<DayProps> = ({
    day,
    presencesMois,
    canClick,
    onClickMorning,
    onClickAfternoon,
    onClickFullDay
}) => {
    const allAgences = useAppSelector((s) => s.agence.allAgences);

    const currentDate = new Date(new Date().setHours(0, 0, 0, 0));

    const isFerie = checkFerie(day);
    const morning = getPresenceForHalfDay(presencesMois, day, DemiJournee.Matin);
    const afternoon = getPresenceForHalfDay(presencesMois, day, DemiJournee.Soir);
    const isMorningEmpty = isEmptyHalfDay(presencesMois, day, DemiJournee.Matin) || currentDate > day;
    const isAfternoonEmpty = isEmptyHalfDay(presencesMois, day, DemiJournee.Soir) || currentDate > day;

    if (isFerie) {
        return <FerieDay day={day} />
    }

    const getClassForPresence = (presence?: PresenceDto) => {
        return {
            present: presence?.present === true,
            absent: presence?.present === false,
        }
    }

    const morngingClassNames = classNames('btn btn-no-style half-day morning', {
        ...getClassForPresence(morning),
        [styles['empty-part-day']]: isMorningEmpty
    });
    const afternoonClassNames = classNames('btn btn-no-style half-day afternoon', {
        ...getClassForPresence(afternoon),
        [styles['empty-part-day']]: isAfternoonEmpty
    });

    const findAgenceByArea = (areaId?: string) => allAgences.find((ag) => ag.areas.find((ar) => ar.guid === areaId));
    const morningAgence =findAgenceByArea(morning?.areaId)
    const afternoonAgence =findAgenceByArea(afternoon?.areaId)

    return (
        <div className="day">
            <button onClick={onClickFullDay} disabled={canClick} className="btn btn-no-style full-day date-number">
                {day.getDate()}
            </button>

            <button onClick={onClickMorning} disabled={canClick}
                className={morngingClassNames}>
                <PresenceIcon lieuPresence={morning?.lieu} />
                <AbsenceIcon raisonAbsence={morning?.raison}></AbsenceIcon>

                {morningAgence && <>({morningAgence.shortName})</>}
            </button>

            <button onClick={onClickAfternoon} disabled={canClick}
                className={afternoonClassNames}>
                <PresenceIcon lieuPresence={afternoon?.lieu}></PresenceIcon>
                <AbsenceIcon raisonAbsence={afternoon?.raison}></AbsenceIcon>

                {afternoonAgence && <>({afternoonAgence.shortName})</>}
            </button>
        </div>
    )
}

