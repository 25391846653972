import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './Loader.scss';

export const Loader = () => {
  return (
    <div className="loading-icon">
      <FontAwesomeIcon icon={faSpinner} className="icon"></FontAwesomeIcon>
    </div>
  )
}
