import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { AgenceDto } from '../../../../../../models/agence.dto';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import { agenceActions } from '../../../../../redux/agence-slice';

export const AgenceFilter = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation()
  const agences = useAppSelector(state => state.agence.allAgences);
  const selectedAgence = useAppSelector(state => state.agence.selectedAgence);

  return (
    <div>
      <h3>{t('presences.filtres.agence')}</h3>
      <Select
        options={agences}
        onChange={a => dispatch(agenceActions.selectAgence(a as AgenceDto))}
        value={selectedAgence}
        getOptionLabel={u => u.name}
        getOptionValue={u => u.guid}
      />
    </div>
  )
}
