import { useTranslation } from 'react-i18next';
import styles from './Delete.module.scss';

export interface DeleteProps {
  onDeleteModeToggled: () => void;
}

export const DeletePresence = ({ onDeleteModeToggled }: DeleteProps) => {
  const { t } = useTranslation();

  return (
    <div className={styles["delete-presence"]}>
      <h2 className="title">{t('saisie.supprimer')}</h2>
      <div className="options-container">
        <div className="options">
          <button className="btn" onClick={onDeleteModeToggled}>
            {t('saisie.suppression')}
          </button>
        </div>
      </div>
    </div>
  )
}
