import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { AgenceDto } from "../../models/agence.dto";
import { AreaDto } from "../../models/area.dto";
import agenceService from "../services/agence.service";

interface ConfigState {
    allAgences: AgenceDto[];
    selectedAgence: AgenceDto | null;
    selectedArea: AreaDto | null;
}

const initialState = {
    allAgences: [],
    selectedAgence: null,
    selectedArea: null,
    state: 'idle'
} as ConfigState


const agenceSlice = createSlice({
    name: "Agences",
    initialState,
    reducers: {
        selectAgence: (state, action: PayloadAction<AgenceDto>) => {
            state.selectedAgence = action.payload
            state.selectedArea = action.payload.areas[0]
        },
        selectArea: (state, action: PayloadAction<AreaDto>) => {
            if (state.selectedAgence?.areas.some(a => a.guid === action.payload.guid)) {
                state.selectedArea = action.payload;
            }
        }
    },
    extraReducers: (builder) => {
        // fetchApolloAgencesAction
        builder.addCase(fetchApolloAgencesAction.fulfilled, (state, action) => {
            state.allAgences = action.payload;
        })
    }
})

export const fetchApolloAgencesAction = createAsyncThunk("agence/fetch", async (_, { dispatch, getState }) => {
    const agences = await agenceService.getAllAgences();
    const state = getState() as ConfigState;

    if (!state.selectedAgence) {
        await dispatch(agenceSlice.actions.selectAgence(agences[0]));
    }

    return agences;
})

export const agenceActions = agenceSlice.actions

const persistConfig = {
    key: 'root',
    storage
};
export default persistReducer(persistConfig, agenceSlice.reducer);

