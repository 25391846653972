import { useTranslation } from 'react-i18next';
import { LieuPresence } from '../../../../../../../enums/lieu-presence.enum';
import { RaisonAbsence } from '../../../../../../../enums/raison-abscence.enum';
import { AbsenceIcon } from '../../../../../shared/AbsenceIcon';
import { PresenceIcon } from '../../../../../shared/PresenceIcon';
import './PresenceAbsenceDetail.scss';

export type PresenceAbsenceDetailProps = {
    lieu?: LieuPresence;
    raison?: RaisonAbsence;
    uniqueUsersCount: number;
    children: React.ReactNode;
};

export const PresenceAbsenceDetail = ({lieu ,raison, uniqueUsersCount, children} : PresenceAbsenceDetailProps) => {
    const { t } = useTranslation();

    return (
    <div className={lieu ? 'lieu' : 'raison'}>
        <div className={lieu ? 'lieu-name' : 'raison-name'}>
        {/* Presence icon, name, and count of users in it */}
        {lieu && <><PresenceIcon lieuPresence={lieu}></PresenceIcon>
            <span>{t('enums.lieuPresence.' + lieu)}</span>
            <span className="lieu-count">{'(' + uniqueUsersCount + ')'}</span></>}

        {/* Absence icon, name, and count of users in it */}
        {raison && <><AbsenceIcon raisonAbsence={raison}></AbsenceIcon>
            <span>{t('enums.raisonAbsence.' + raison)}</span>
            <span className="raison-count">{'(' + uniqueUsersCount + ')'}</span></>}
        </div>
        <div className="users">{children}</div>
    </div>
    );
};