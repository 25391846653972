export const screenWidth = () => {
    var width = (window.innerWidth > 0) ? window.innerWidth : window.screen.width;
    return width;
}

export const screenHeight = () => {
    var height = (window.innerHeight > 0) ? window.innerHeight : window.screen.height;
    return height;
}

export const mobileMode = (width : number) => {
    if (width >= 320 && width <= 480) {
        return true;
    } else return false;
}
