export enum Groupe {
  RH = 'RH',
  Commercial = 'Commercial',
  CodingFactory = 'CodingFactory',
  Administration = 'Administration',
}

export const stringToGroup = (groupeString: string): Groupe | null => {
  return Object.values(Groupe).includes(groupeString as Groupe) ? groupeString as Groupe : null;
}
