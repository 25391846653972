import { useEffect, useState } from "react";
import { mobileMode } from "../helpers/screen-helper";

export const useMobileDetect = () => {
    const [isMobile, setMobile] = useState(false);
    const [size, setSize] = useState(0);
    useEffect(() => {
        const updateSize = () => {
            setSize(window.screen.width);
        }
        updateSize();
        window.addEventListener('resize', updateSize);
        setMobile(mobileMode(size));
        return () => window.removeEventListener("resize", updateSize);
    }, [size]);

    return isMobile;
}