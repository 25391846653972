import { useTranslation } from 'react-i18next';
import styles from './PresenceType.module.scss';

import { FC } from 'react';
import Select from 'react-select';
import { LieuPresence } from '../../../../enums/lieu-presence.enum';
import { RaisonAbsence } from '../../../../enums/raison-abscence.enum';
import { useAppSelector } from '../../../../redux/hooks';
import { ButtonGroup, ButtonGroupButton } from '../../../shared/buttonGroup/buttonGroup';
import { EditionParameter } from '../EditionParameter';

const ShowPresenceAbsence: FC<{ present?: boolean, onPresentChanged: (present: boolean) => void }> = ({ present, onPresentChanged }) => {
  const { t } = useTranslation();

  return (
    <ButtonGroup
      buttons={[
        { children: t('saisie.presence'), value: true },
        { children: t('saisie.absence'), value: false },
      ]}
      selectedValue={present}
      onSelected={onPresentChanged} />
  )
}

const ShowPresenceLieu: FC<{ lieu?: LieuPresence, onLieuChanged: (lieu: LieuPresence) => void }> = ({ lieu, onLieuChanged }) => {
  const { t } = useTranslation();
  const buttons = Object.values(LieuPresence).map(lp => ({
    value: lp,
    children: t('enums.lieuPresence.' + lp)
  } as ButtonGroupButton<LieuPresence>))

  return <ButtonGroup buttons={buttons} onSelected={onLieuChanged} selectedValue={lieu} />;
}

export const ShowRaisonAbsence: FC<{ raisonAbsence?: RaisonAbsence, onRaisonChanged: (raison: RaisonAbsence) => void }> = ({ raisonAbsence, onRaisonChanged }) => {
  const { t } = useTranslation();

  const buttons = Object.values(RaisonAbsence).map(raison => ({
    value: raison,
    children: t('enums.raisonAbsence.' + raison)
  } as ButtonGroupButton<RaisonAbsence>))

  return <ButtonGroup buttons={buttons} onSelected={onRaisonChanged} selectedValue={raisonAbsence} />;
}

const ShowAgenceSelection: FC<{ agenceId?: string, onSelectAgence: (agenceId: string) => void }> = ({ agenceId, onSelectAgence }) => {
  const { t } = useTranslation();
  const agenceState = useAppSelector(state => state.agence)
  const selectedAgence = agenceState.allAgences.find(a => a.guid === agenceId);

  return <>
    <h3>{t('presences.filtres.agence')}</h3>
    <Select
      options={agenceState.allAgences}
      getOptionLabel={u => u.name}
      getOptionValue={u => u.guid}
      value={selectedAgence}
      onChange={a => onSelectAgence(a!.guid)}
    />
  </>
}

const ShowAreaSelection: FC<{ agenceId: string, areaId?: string, onSelectArea: (areaId: string) => void }> = ({ agenceId, areaId, onSelectArea }) => {
  const agenceState = useAppSelector(state => state.agence)
  const allAreas = agenceState.allAgences.find(a => a.guid === agenceId)!.areas;
  const selectedArea = allAreas.find(a => a.guid === areaId);
  const { t } = useTranslation();

  return <>
    <h3>{t('presences.filtres.area')}</h3>
    <Select
      options={allAreas}
      onChange={a => onSelectArea(a!.guid)}
      value={selectedArea}
      getOptionLabel={u => u.name}
      getOptionValue={u => u.guid}
    />
  </>
}

export interface PresenceTypeProps {
  editionParameter: EditionParameter;
  onSetEditionParameter: (editionParameter: EditionParameter) => void;
}

export const PresenceType: FC<PresenceTypeProps> = ({ editionParameter, onSetEditionParameter }) => {
  const { t } = useTranslation();
  const agenceState = useAppSelector(state => state.agence)

  const onPresentChanged = (present: boolean) => {
    onSetEditionParameter({
      agenceId:editionParameter.agenceId,
      areaId: editionParameter.areaId,
      present
    });
  }

  const onLieuChanged = (lieu: LieuPresence) => {
    onSetEditionParameter({
      ...editionParameter,
      lieu
    })
  }

  const onRaisonChanged = (raison: RaisonAbsence) => {
    onSetEditionParameter({
      ...editionParameter,
      raison
    })
  }

  const onAgenceChanged = (agenceId: string) => {
    onSetEditionParameter({
      ...editionParameter,
      agenceId,
      areaId: agenceState.allAgences.find(a => a.guid === agenceId)!.areas[0].guid
    })
  }

  const onAreaChanged = (areaId: string) => {
    onSetEditionParameter({
      ...editionParameter,
      areaId
    })
  }

  return (
    <div className={styles["presence-type"]}>
      <div className={styles["options-container"]}>
        <ShowAgenceSelection agenceId={editionParameter.agenceId} onSelectAgence={onAgenceChanged} />
        {editionParameter.agenceId && <ShowAreaSelection agenceId={editionParameter.agenceId} areaId={editionParameter.areaId} onSelectArea={onAreaChanged} />}

        {editionParameter.areaId && (
          <>
            <h3 className="title">{t("saisie.renseigner")}</h3>
            <ShowPresenceAbsence present={editionParameter.present} onPresentChanged={onPresentChanged} />
            {editionParameter.present && <ShowPresenceLieu lieu={editionParameter.lieu} onLieuChanged={onLieuChanged} />}
            {editionParameter.present === false && <ShowRaisonAbsence raisonAbsence={editionParameter.raison} onRaisonChanged={onRaisonChanged} />}
          </>
        )}
      </div>
    </div>
  )
}
