import { faUser, faSun, faMoon } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { logout } from '../../../redux/user-reducer';
import './Header.scss';
import packageJson from '../../../../package.json';
import useLocalStorage from 'use-local-storage';

export const Header = () => {
  const user = useAppSelector(state => state.user.user);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const disconnect = () => {
    dispatch(logout());
  };

  const [theme, setTheme] = useLocalStorage('theme', 'dark');
  document.body.setAttribute('data-theme', theme);

  const switchTheme = () => {
    setTheme(theme === 'light' ? 'dark' : 'light');
    document.body.setAttribute('data-theme', theme);
  };

  return (
    <div className="header">
      <div className="left">
        <div className="brand"><img src="/favicon.png" alt="logo" className="brand-logo"></img>présent <div className="exclamation">!</div></div>
        <div className="version">v {packageJson.version}</div>
      </div>
      <div className='change-theme' onClick={switchTheme}>
        <FontAwesomeIcon className='theme-icon' icon={faSun} />
        <input
          type="checkbox"
          id="toggletheme"
          className='cbx hidden'
          checked={theme === 'dark'} />
        <label
          className="switch" />
        <FontAwesomeIcon className='theme-icon' icon={faMoon} />
      </div>
      <div className="right">
        {user && <FontAwesomeIcon icon={faUser}></FontAwesomeIcon>}
        {user && <div className="user-name">{user?.name}</div>}
        {user && <button className="btn btn-tertiary" onClick={disconnect}>{t('header.disconnect')}</button>}
      </div>
    </div>
  );
};
