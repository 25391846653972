import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';

import './SelectMonth.scss';

export type SelectMonthProps = {
  mois: Date;
  setMois?: (p: Date) => void;
  setDayWatched?: (p: Date) => void;
  dayWatched?: Date;
};

export const SelectMonth = ({ mois, setMois, setDayWatched, dayWatched }: SelectMonthProps) => {
  const moisString = mois.toLocaleString('default', { month: 'long' }) + ' ' + mois.getFullYear();

  const clickArrow = (monthIncrement: number) => {
    if (setMois) {

      const newMonth = new Date(mois);
      newMonth.setDate(1);
      newMonth.setMonth(mois.getMonth() + monthIncrement);
      setMois(newMonth);

      if (setDayWatched) {
        setDayWatched(new Date(0));
      }
    }
  };

  const clickMonth = () => {
    if (setMois) {
      setMois(new Date());
    }
    if (setDayWatched) {
      setDayWatched(new Date());
    }
  };

  return (
    <div className="select-month">
      {setMois &&
        <button className="btn btn-tertiary" onClick={() => clickArrow(-1)}>
          <FontAwesomeIcon className="arrow-icon" icon={faArrowLeft}></FontAwesomeIcon>
        </button>
      }

      <button className="btn btn-tertiary month" onClick={() => clickMonth()}>{moisString}</button>

      {setMois &&
        <button className="btn btn-tertiary" onClick={() => clickArrow(1)}>
          <FontAwesomeIcon className="arrow-icon" icon={faArrowRight}></FontAwesomeIcon>
        </button>
      }
    </div>
  );
};
