import { useEffect } from 'react';
import { Redirect, Route } from 'react-router';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { logout, setToken, setUser } from '../../redux/user-reducer';
import userService from '../../services/user.service';


export const PrivateRoute = (props: any) => {
  const { children, ...otherProps } = props;
  const dispatch = useAppDispatch();
  let token = useAppSelector(state => state.user.token);
  let user = useAppSelector(state => state.user.user);
  let currentUserExpirationDate = useAppSelector(state => state.user.currentUserExpirationDate);

  useEffect(() => {
    if (token && !user) {
      userService.getCurrentUser().then(user => {
        dispatch(setUser(user));
      });
    }
  }, [token, user, dispatch]);

  if ((!currentUserExpirationDate || new Date().getTime() > currentUserExpirationDate)) {
    userService.getCurrentUser().then(user => {
      dispatch(setUser(user));
    });
  }

  



  if (!token) {
    token = localStorage.getItem('token');
    if (token) {
      dispatch(setToken(token));
      localStorage.removeItem('token');
    } else {
      if (user) {
        dispatch(logout());
      }
      return <Redirect to="/login"></Redirect>;
    }
  }

  return (
    <Route {...otherProps}>
      {children}
    </Route>
  )
}
