import './Login.scss';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { Redirect } from 'react-router';
import userService from '../../services/user.service';
import { useEffect, useState } from 'react';
import { setToken, setUser } from '../../redux/user-reducer';
import isDev from '../../helpers/process-helper';
import * as microsoftTeams from "@microsoft/teams-js";
import './Login.mobile.scss'

export const Login = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [userName, setUserName] = useState('');
  const [isTeams, setIsTeams] = useState(false);
  const token = useAppSelector(state => state.user.token);
  const isDevelopment: boolean = isDev();
  useEffect(() => {
    microsoftTeams.initialize(() => {
      microsoftTeams.getContext((context) => {
        if (!!context) {
          setIsTeams(true);
        }
      })
    })
  }, [])
  if (token) {
    return <Redirect to="/"></Redirect>;
  }

  const submit = async () => {
    const userLogin = await userService.createUser({ name: userName });
    dispatch(setToken(userLogin.token));
    dispatch(setUser(userLogin.user));
  }

  const teams = async () => {
    microsoftTeams.authentication.authenticate({
      url: window.location.origin + "/loginTeam",
      width: 600,
      height: 535,
      successCallback: (result) => {
        window.location.href = "/";
      },
      failureCallback: (result) => {
      }
    });
  }

  const keyup = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      submit();
    }
  };

  return (
    <div className="login">
      <div className="login-form">
        <div className="image-container">
          <img className="image" src="/images/apollo.png" alt="Logo Apollo" />
        </div>
        <div id="login-container-mobile" className="login-container">
          {!isTeams && <div className="form-container">
            <a href="/auth/microsoft" className="btn btn-primary">{t('login.microsoft')}</a>
          </div>}
          {isTeams && <div className="form-container">
            <span onClick={teams} className="btn btn-primary">{t('login.microsoft')}</span>
          </div>}
          {
            isDevelopment && (
              <div id="name-input-mobile" className="name-input">
                <input className="full-width" type="text" placeholder={t('login.yourName')} onKeyUp={keyup}
                  onChange={event => setUserName(event.target.value)} />
                <button className="btn btn-primary" onClick={submit}>Go !</button>
              </div>)
          }

        </div>
      </div>
    </div>
  );
}


