import { PresenceDto } from '../../../../../../models/presence.dto';
import { capitalizeFirstLetter } from '../../../../../helpers/string-helpers';
import { checkFerie, compareDate } from '../../../../../helpers/date-helpers';
import './JourSemaine.scss';
import './JourSemaine.mobile.scss';
import { CollapsePresence } from './collapse-presence/CollapsePresence';

export type JourSemaineProps = {
  day: Date;
  presences: PresenceDto[];
};

export const JourSemaine = ({ day, presences }: JourSemaineProps) => {
  const title = day.toLocaleString('default', { weekday: 'long', day: 'numeric', month: 'long' });

  const isFerie = checkFerie(day);
  const isToday = compareDate(day, new Date());
  const isDayPassed = day < new Date(new Date().setHours(0, 0, 0, 0));

  return (
    <div className={'week-day ' + (isFerie ? 'holiday ' : isToday ? 'today ' : '') + (isDayPassed ? 'day-passed' : '')}>
      <div className={"title " + (isDayPassed ? 'day-passed' : '')}>
        {capitalizeFirstLetter(title)}
      </div>
      {!isFerie && <>
        <CollapsePresence
          isPresent={true} isPassed={isDayPassed} presences={presences}/>
        <CollapsePresence
          isPresent={false} isPassed={isDayPassed} presences={presences}/></>}
    </div>
  );
};