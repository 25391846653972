import { PresenceDto } from '../../models/presence.dto';
import { httpClient } from '../config/axios';
import { getUTCDate } from '../helpers/date-helpers';

const presenceService = {
  castJours(presences: PresenceDto[]): PresenceDto[] {
    return presences.map(presence => {
      presence.jour = new Date(presence.jour ?? '');
      return presence;
    });
  },
  async setPresences(presences: PresenceDto[], year: number, month: number): Promise<PresenceDto[]> {
    const response = await httpClient.post<PresenceDto[]>(`/presences/${year}/${month}`, presences.map(p => ({
      ...p, jour: getUTCDate(p.jour as Date),
    })));
    return response.data;
  },
  async getPresences(year: number, month: number): Promise<PresenceDto[]> {
    const response = await httpClient.get<PresenceDto[]>(`/presences/${year}/${month}`);
    return this.castJours(response.data);
  },
  async getPresencesInRange(areaId: string, start: Date, end: Date): Promise<PresenceDto[]> {
    const response = await httpClient.get<PresenceDto[]>(`/presences/by-period?areaId=${areaId}&start=${getUTCDate(start)}&end=${getUTCDate(end)}`);
    return this.castJours(response.data);
  },
};

export default presenceService;
