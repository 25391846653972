import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faSun, faUniversity } from '@fortawesome/free-solid-svg-icons';
import { RaisonAbsence } from '../../../enums/raison-abscence.enum';

export type AbsenceIconProps = {
  raisonAbsence: RaisonAbsence | undefined,
};

export const AbsenceIcon = ({ raisonAbsence }: AbsenceIconProps) => {
  let icon: IconDefinition | null = null;
  switch (raisonAbsence) {
    case RaisonAbsence.Conge:
      icon = faSun;
      break;
    case RaisonAbsence.Ecole:
      icon = faUniversity;
      break;
  }

  return icon ? <FontAwesomeIcon icon={icon}></FontAwesomeIcon> : null;
}
