import feriesList from "@socialgouv/jours-feries";

export const getDaysInMonth = (month: Date): Date[] => {
  const daysInMonth: Date[] = [];
  const nbDays = new Date(month.getFullYear(), month.getMonth() + 1, 0).getDate();
  for (let i = 0; i < nbDays; i++) {
    const date = new Date(month.getFullYear(), month.getMonth(), i + 1);
    // filter saturday and sunday
    if (date.getDay() !== 6 && date.getDay() !== 0) {
      daysInMonth.push(date);
    }
  }
  return daysInMonth;
}

export const daysOfWeek = [1, 2, 3, 4, 5];
const MONDAY = daysOfWeek[0];

export const createWeeksFromDates = (daysInMonth: Date[]) => {
  const weeks: Date[][] = [];
  let weekIndex = -1;

  daysInMonth.forEach(day => {
    if (weeks.length === 0 || day.getDay() === MONDAY) {
      weekIndex++;
      weeks[weekIndex] = [];
    }
    weeks[weekIndex].push(day);
  });

  return weeks;
}


export const getDaysInWeek = (week: Date) => {
  const monday = getPreviousMonday(week)

  return [...new Array(5)].map((_, i) =>
    new Date(monday.getFullYear(), monday.getMonth(), monday.getDate() + i)
  )
}

export const checkFerie = (date: Date): boolean => {
  const feries: Date[] = Object.values(feriesList(date.getFullYear()));
  const isFerie = feries.some(e => compareDate(e, date))
  return isFerie;
}

export const compareDate = (date1: Date, date2: Date) => {
  const valid = date1.getFullYear() === date2.getFullYear()
    && date1.getMonth() === date2.getMonth()
    && date1.getDate() === date2.getDate()
  return valid;
}

export const addHours = (date: Date, hours: number): number => {
  date.setHours(date.getHours() + hours)
  return date.getTime();
}

export const dateToUtcFormat = (date: Date): string => {
  return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
}

export const getDayName = (day: number, referenceDays: Date[]): string | undefined => {
  return referenceDays.find(d => d.getDay() === day)?.toLocaleString('default', { weekday: 'long' });
};

export const getPreviousMonday = (date: Date): Date => {
  const lundiDate = new Date(date);
  lundiDate.setDate(date.getDate() - ((date.getDay() - 1) % 7));
  return lundiDate;
};

export const getUTCDate = (date: Date): Date => {
  return new Date(Date.UTC(date.getFullYear() as number, date.getMonth() as number, date.getDate() as number));
};
