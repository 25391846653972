import { useTranslation } from 'react-i18next';
import { PresenceDto } from '../../../../../../../models/presence.dto';
import { LieuPresence } from '../../../../../../enums/lieu-presence.enum';
import { RaisonAbsence } from '../../../../../../enums/raison-abscence.enum';
import { useState } from 'react';
import useCollapse from 'react-collapsed';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { PresenceAbsenceDetail } from './presence-absence-detail/PresenceAbsenceDetail';
import './CollapsePresence.scss';


export type CollapsePresenceProps = {
  isPresent : boolean;
  isPassed : boolean;
  presences: PresenceDto[];
};

const getNbPresences = (presences : PresenceDto[], isPresent : boolean ) : number => { 
    return presences
    .filter(p => p.present === isPresent)
    .map(p => p.user)
    .filter((user, index, users) => users.findIndex(u => u?.guid === user?.guid) === index)
    .length
}

export const CollapsePresence = ({isPresent ,isPassed, presences} : CollapsePresenceProps) => {
    const { t } = useTranslation();
    const [isExpanded, setExpanded] = useState(true);
    const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded: isExpanded });


    const getElementsPresences = () => {
        return Object.values(LieuPresence)
        .map(lieuPresence => ({
            lieu: lieuPresence,
            presencesLieu: presences.filter(p => p.present && p.lieu === lieuPresence),
        }))
        .filter(p => p.presencesLieu.length > 0);
    };

    const showLieuxPresences = () => {
        return getElementsPresences()
        .map(({ lieu, presencesLieu }) => {
            //Array of users to display the good count of people
            const uniqueUsersCount = presencesLieu.map(p => p.user)
            .filter((user, index, self) => self.findIndex(user2 => user2?.guid === user?.guid) === index).length;

            //JSX element
            const users = presencesLieu.map(p => p.user)
            .filter((user, index, self) => self.findIndex(user2 => user2?.guid === user?.guid) === index)
            .sort((a, b) => a?.name.localeCompare(b?.name ?? "") ?? 0)
            .map(user => {
                const presencesUserLieu = presencesLieu.filter(p => p.user?.guid === user?.guid);
                const demiJournee = presencesUserLieu.length === 1 ? presencesUserLieu[0].demiJournee : null;

                return <div key={user?.guid} className="user">
                {user?.name} {demiJournee && `(${t('enums.demiJournee.' + demiJournee)})`}
                </div>;
            });

            return (
            <PresenceAbsenceDetail
                lieu={lieu}
                uniqueUsersCount={uniqueUsersCount}
                key={lieu}> 
                {users}
            </PresenceAbsenceDetail>
            );
        });
    };

    const getElementsAbsences = () => {
        return Object.values(RaisonAbsence)
        .map(raison => ({
            raison,
            absences: presences.filter(p => !p.present && p.raison === raison)
        }))
        .filter(p => p.absences.length > 0);
    };

    const showRaisonsAbsences = () => {
        return getElementsAbsences()
        .map(({ raison, absences }) => {
            const uniqueUsersCount = absences.map(p => p.user)
            .filter((user, index, self) => self.findIndex(user2 => user2?.guid === user?.guid) === index).length;

            const users = absences.map(p => p.user)
            .filter((user, index, self) => self.findIndex(user2 => user2?.guid === user?.guid) === index)
            .map(user => {
                const absencesUserRaison = absences.filter(a => a.user?.guid === user?.guid);
                const demiJournee = absencesUserRaison.length === 1 ? absencesUserRaison[0].demiJournee : null;

                return <div key={user?.guid} className="user">
                {user?.name} {demiJournee && `(${t('enums.demiJournee.' + demiJournee)})`}
                </div>;
            });

            return (
            <PresenceAbsenceDetail
                raison={raison}
                uniqueUsersCount={uniqueUsersCount}
                key={raison}> 
                {users}
            </PresenceAbsenceDetail>
            );
        });
    };

    return (
        <div className={(isPresent ? 'presences ' : 'absences ') + (isPassed ? 'day-passed' : '')}>
            <div className='toggle-main-title' {...getToggleProps({
            onClick: () => setExpanded((x: boolean) => !x)
            })}>
            <FontAwesomeIcon icon={faChevronDown} className={isExpanded ? '' : 'rotate90-anti-clockwise'}></FontAwesomeIcon>
            <div className='title-name'>{isPresent ? 'Présences' : 'Absences'} ({isPresent ? getNbPresences(presences ,true) : getNbPresences(presences, false)})</div>
            </div>
            <div {...getCollapseProps()}>
            {isPresent ? showLieuxPresences() : showRaisonsAbsences()}
            </div>
        </div>
    );
};