import { PresenceDto } from '../../../../../models/presence.dto';
import { DemiJournee } from '../../../../enums/demi-journee.enum';
import { createWeeksFromDates, daysOfWeek, getDayName, getDaysInMonth } from '../../../../helpers/date-helpers';
import { Loader } from '../../shared/loading/Loader';
import { SelectMonth } from '../../shared/select-month/SelectMonth';
import './SaisieMois.scss';
import { checkFerie } from '../.././../../helpers/date-helpers'
import { EditionParameter } from '../EditionParameter';
import { useMemo } from 'react';
import { comparePresence, getPresenceForHalfDay, getPresencesForDay } from '../../presences/utils/presenceDtoUtils';
import { PaddingDays } from './days/PaddingDays';
import { Day } from './days/Day';

export interface SaisieMoisProps {
  editionParameter: EditionParameter,
  presencesMois: PresenceDto[];
  setPresencesMois: (p: PresenceDto[]) => void;
  mois: Date;
  setMois?: (p: Date) => void;
  loading: boolean;
  isDeleteMode: boolean;
};

export const SaisieMois = ({ editionParameter, presencesMois, setPresencesMois, mois, setMois, loading, isDeleteMode }: SaisieMoisProps) => {
  const daysInMonth = useMemo(() => getDaysInMonth(mois), [mois]);
  const weeks = useMemo(() => createWeeksFromDates(daysInMonth), [daysInMonth]);

  const currentDate = new Date(new Date().setHours(0, 0, 0, 0));
  const nbPaddingDays = daysInMonth[0].getDay() - daysOfWeek[0];

  const editionParameterIsValid = editionParameter.lieu || editionParameter.raison

  const disabledRecurrentWeek = (week: Date[]): boolean => {
    if (week) {
      return (!editionParameterIsValid) || !week.some(date => date >= currentDate);
    }

    return true;
  }

  const disabledRecurrentDay = (day: number): boolean => {
    if (day) {
      const daysDates = daysInMonth.filter(d => d.getDay() === day && d >= currentDate);
      return (!editionParameterIsValid) || daysDates.length === 0;
    }
    return true;
  }

  const disabled = (day: Date): boolean => {
    const isFerie = checkFerie(day);
    if (isFerie) {
      return true;
    }

    if ((editionParameterIsValid || isDeleteMode) && day >= currentDate) {
      return false;
    }

    return true;
  }

  const removePresences = (oldPresences: (PresenceDto | undefined)[]) => {
    setPresencesMois(presencesMois.filter(p => !oldPresences.includes(p)));
  }

  const addOrUpdatePresences = (oldPresences: (PresenceDto | undefined)[], newPresences: PresenceDto[]) => {
    setPresencesMois([
      ...(presencesMois.filter(p => !oldPresences?.includes(p))),
      ...newPresences
    ]);
  }

  const clickHalfDay = (day: Date, halfDay: DemiJournee) => {
    const oldPresence = getPresenceForHalfDay(presencesMois, day, halfDay);

    if (isDeleteMode) {
      removePresences([oldPresence])
    }
    else {
      const newPresence = {
        ...editionParameter,
        jour: new Date(day),
        demiJournee: halfDay,
      } as PresenceDto;

      if (comparePresence(oldPresence, newPresence)) {
        removePresences([oldPresence])
      } else {
        addOrUpdatePresences([oldPresence], [newPresence]);
      }
    }
  };

  const clickFullDay = (day: Date,) => {
    const oldPresences = getPresencesForDay(presencesMois, day);
    const newPresences = [{
      ...editionParameter,
      jour: new Date(day),
      demiJournee: DemiJournee.Matin
    }, {
      ...editionParameter,
      jour: new Date(day),
      demiJournee: DemiJournee.Soir
    }];

    if (isDeleteMode) {
      removePresences(oldPresences);
    }
    else if (newPresences.every(presence => comparePresence(oldPresences?.find(p => p.demiJournee === presence.demiJournee), presence))) {
      removePresences(oldPresences);
    } else {
      addOrUpdatePresences(oldPresences, newPresences);
    }
  };

  const addPresenceTypeForDays = (days: Date[]) => {
    setPresencesMois([
      ...presencesMois.filter(p => !days.some(day => p.jour?.getDate() === day.getDate())),
      ...days.flatMap(d => ([
        {
          ...editionParameter,
          jour: new Date(d),
          demiJournee: DemiJournee.Matin,
        }, {
          ...editionParameter,
          jour: new Date(d),
          demiJournee: DemiJournee.Soir,
        },
      ])),
    ]);
  }

  const clickWeekDay = (day: number) => {
    const daysDates = daysInMonth.filter(d => d.getDay() === day && !disabled(d));

    addPresenceTypeForDays(daysDates);
  };

  const clickFullWeek = (week: Date[]) => {
    const days = week.filter(date => !disabled(date));
    addPresenceTypeForDays(days);
  }

  const daysComponents = daysInMonth.map(day =>
    <Day
      key={day.getTime()}
      day={day}
      presencesMois={presencesMois}
      canClick={disabled(day)}
      onClickMorning={() => clickHalfDay(day, DemiJournee.Matin)}
      onClickAfternoon={() => clickHalfDay(day, DemiJournee.Soir)}
      onClickFullDay={() => clickFullDay(day)}
    />);


  const dayNames = daysOfWeek.map(d =>
    <button className="btn btn-tertiary day-name" disabled={disabledRecurrentDay(d)} key={'weekDay-' + d}
      onClick={() => clickWeekDay(d)}>{getDayName(d, daysInMonth)}</button>
  );

  const weeksComponents = weeks.map((week, index) =>
    <button className="btn btn-tertiary week" disabled={disabledRecurrentWeek(week)} key={'week-' + index}
      onClick={() => clickFullWeek(week)}>S{index + 1}</button>
  );

  return (
    <div className="saisie-mois">
      <div className="calendar-container">
        <SelectMonth mois={mois} setMois={setMois}></SelectMonth>
        {loading ?
          <Loader></Loader>
          : <div className="calendar">
            <div className="weeks">
              {weeksComponents}
            </div>
            <div className="day-names">
              {dayNames}
            </div>
            <div className="days">
              <PaddingDays count={nbPaddingDays} />
              {daysComponents}
            </div>
          </div>
        }
      </div>
    </div>
  );
}
