import { PresenceDto } from '../../../../../models/presence.dto';
import { getDaysInWeek } from '../../../../helpers/date-helpers';
import './Semaine.scss';
import './Semaine.mobile.scss';
import { JourSemaine } from './jour-semaine/JourSemaine';

export type SemaineProps = {
  day: Date;
  presences: PresenceDto[];
};

export const Semaine = ({ day, presences }: SemaineProps) => {
  const days = getDaysInWeek(day).map(day => {
    const presencesDay = presences.filter(p => p.jour?.getDate() === day.getDate());

    return <JourSemaine key={day.getDate()} day={day} presences={presencesDay} />
  });

  return (
    <div className="week-view">
      <div className="scroll-week-view">
        {days}
      </div>
    </div>
  )
};
