import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { PresenceDto } from '../../../../../../models/presence.dto';
import { UserDto } from '../../../../../../models/user.dto';
import { DemiJournee } from '../../../../../enums/demi-journee.enum';
import { checkFerie, compareDate } from '../../../../../helpers/date-helpers';
import { useMobileDetect } from '../../../../../hooks/useMobileDetect';
import { Tooltip } from '../../../../shared/tooltip/Tooltip';
import './JourMois.scss';
import './JoursMois.mobile.scss';
import { samePresenceAllDay } from '../../utils/presenceDtoUtils';
import { FC } from 'react';

const PresentTooltip: FC<{
  presencesUser: PresenceDto[],
  userName: string
}> = ({ presencesUser, userName }) => {
  const { t } = useTranslation();

  return (
    <div className="user-tooltip tooltip">
      <div className="full-name">{userName}</div>
      <div className="presence-infos">
        {samePresenceAllDay(presencesUser) ? presencesUser[0].present ? t('enums.lieuPresence.' + presencesUser[0].lieu) : t('enums.raisonAbsence.' + presencesUser[0].raison)
          : presencesUser.map(p => (
            <div className="presence-info" key={p.demiJournee}>{
              (p.present && t('enums.lieuPresence.' + p.lieu) + ` (${t('enums.demiJournee.' + p.demiJournee)})`) ||
              (!p.present && t('enums.raisonAbsence.' + p.raison) + ` (${t('enums.demiJournee.' + p.demiJournee)})`)
            }
            </div>
          ))
        }
      </div>
    </div>
  )
};

export type JourProps = {
  presences: PresenceDto[];
  date: Date;
  dayWatched: Date;
  onClickOnDay: (day: Date) => void;
};

export const JourMois = ({ presences, date, dayWatched, onClickOnDay }: JourProps) => {
  const isMobile = useMobileDetect();

  // Utilisé pour le mobile : Sélection de la date lors du clic d'un jour dans la vue mois
  const isSelected = compareDate(dayWatched, date);
  const presencesJour = presences.filter(p => p.jour?.getDate() === date.getDate());
  const usersJour = presencesJour.map(p => p.user as UserDto)
    .filter((user, index, self) => self.findIndex(u => u?.guid === user.guid) === index);


  const getColorUser = (presencesUser: PresenceDto[], demiJournee: DemiJournee) => {
    if (presencesUser.find(p => p.present && p.demiJournee === demiJournee)) {
      return 'color-present';
    }

    if (presencesUser.find(p => !p.present && p.demiJournee === demiJournee)) {
      return 'color-absent';
    }

    return "color-default";
  };

  const showUsers = usersJour.map(user => {
    const initiales = user.name.split(' ').map(str => str[0]).join('').toUpperCase();
    const presencesUser = presencesJour.filter(p => p.user?.guid === user.guid);

    presencesUser.sort((a, b) => {
      return a.demiJournee!.localeCompare(b.demiJournee!);
    });

    return (
      <Tooltip className="user-mini" key={user.guid}>
        <div className="day">
          <div className={"morning " + getColorUser(presencesUser, DemiJournee.Matin)}></div>
          <div className={"afternoon " + getColorUser(presencesUser, DemiJournee.Soir)}></div>
        </div>
        <div className="name">{initiales}</div>

        <PresentTooltip userName={user.name} presencesUser={presencesUser} />
      </Tooltip>
    );
  });

  const isFerie = checkFerie(date);
  const isToday = compareDate(date, new Date());
  const isDayPassed = date < new Date(new Date().setHours(0, 0, 0, 0));

  const monthDayClassname = classNames("month-day", {
    selected: isSelected,
    "day-passed": isDayPassed,
    holiday: isFerie,
    today: !isFerie && isToday
  })

  return (
    <div className={monthDayClassname} onClick={() => onClickOnDay(date)}>
      <div className="date">
        {date.getDate()}
      </div>
      {!isMobile && !isFerie && <div className="users">{showUsers}</div>}
    </div>
  );
};
