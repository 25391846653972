import { PresenceDto } from "../../../../../models/presence.dto";
import { DemiJournee } from "../../../../enums/demi-journee.enum";
import { compareDate } from "../../../../helpers/date-helpers";

export const comparePresence = (presence1?: PresenceDto, presence2?: PresenceDto): boolean => {
  let valid = false;

  if (presence1 && presence2) {
    // Comparaison de deux absences
    if (presence1?.present === false && presence2?.present === false) {
      valid = (presence1?.raison === presence2?.raison);
    }

    // Comparaison de deux présences
    if (presence1?.present === true && presence2?.present === true) {
      valid = (presence1?.lieu === presence2?.lieu);
    }
  }

  return valid
}

export const getPresenceForHalfDay = (presencesMois: PresenceDto[], day: Date, halfDay: DemiJournee): PresenceDto | undefined => {
  return presencesMois.find(p => p.jour?.getDate() === day.getDate() && p.demiJournee === halfDay);
}

export const getPresencesForDay = (presencesMois: PresenceDto[], day: Date): PresenceDto[] => {
  return presencesMois.filter(p => p.jour?.getDate() === day.getDate());
}


export const isEmptyHalfDay = (presencesMois: PresenceDto[], day: Date, halfDay: DemiJournee): boolean => {
  //Find presence corresponding to the day and the half day
  return presencesMois.some(presence => compareDate(day, presence.jour!) && presence.demiJournee === halfDay);
}

export const samePresenceAllDay = (presencesDay: PresenceDto[]) => {
  if (presencesDay.length !== 2) {
    return false;
  }

  const presenceAndSameLieu = presencesDay.find(p => p.present) && presencesDay[0].lieu === presencesDay[1].lieu
  const absenceAndSameReason = presencesDay.find(p => !p.present) && presencesDay[0].raison === presencesDay[1].raison;

  return presenceAndSameLieu || absenceAndSameReason;
}

export const presencesOfDay = (presences: PresenceDto[], day: Date): PresenceDto[] => presences
  .filter(presence => presence.jour && compareDate(presence.jour, day));