import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { PresenceDto } from '../../../../../models/presence.dto';
import { UserDto } from '../../../../../models/user.dto';
import { Groupe } from '../../../../enums/groupe.enum';
import { PresencesFiltersModel } from '../../../../models/presences-filters.model';
import { SelectOption } from '../../../../models/select-option.model';
import { useAppSelector } from '../../../../redux/hooks';
import { SlidePanel } from '../../../shared/slide-panel/SlidePanel';
import './PresencesFilters.scss';
import { AgenceFilter } from './agence-filter/AgenceFilter';
import { AreaFilter } from './area-filter/AreaFilter';

export interface PresencesFiltersProps {
  filters: PresencesFiltersModel;
  setFilters: (filters: PresencesFiltersModel) => void;
  open: boolean;
  setOpen: (open: boolean) => void;
  presences: PresenceDto[];
};



export const PresencesFilters = ({ filters, setFilters, open, setOpen, presences }: PresencesFiltersProps) => {
  const { t } = useTranslation();

  const [users, setUsers] = useState<UserDto[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<UserDto[]>([]);
  const [groupeOptions] = useState<SelectOption<Groupe>[]>(Object.values(Groupe)
    .map(groupe => ({ value: groupe, label: t(`enums.groupe.${groupe}`) })));
  const [selectedGroupe, setSelectedGroupe] = useState<SelectOption<Groupe> | null>(null);
  const selectedAgence = useAppSelector(state => state.agence.selectedAgence)

  useEffect(() => {
    setUsers(presences.map(p => p.user)
      .filter(user => !!user)
      .map(u => u as UserDto)
      .filter((user, index, self) => self.findIndex(u => u.guid === user.guid) === index));
  }, [presences]);

  useEffect(() => {
    setSelectedGroupe(groupeOptions.find(e => filters.groupe === e.value) || null);
  }, [filters.groupe, groupeOptions]);


  useEffect(() => {
    if (!filters.groupe) {
      setSelectedGroupe(null);
    }
    if (filters.users.length === 0) {
      setSelectedUsers([]);
    }
  }, [filters]);


  const setUsersFilter = (users: UserDto[]) => {
    setSelectedUsers(users);
    setFilters({
      ...filters,
      users: users.map(u => u.guid as string),
    });
  };

  const selectGroupe = (groupeOption: SelectOption<Groupe> | null) => {
    setSelectedGroupe(groupeOption);
    setFilters({
      ...filters,
      groupe: groupeOption?.value,
    });
  };

  const reinitFilters = () => {
    setFilters({
      users: [],
      lieuPresence: null,
      raisonAbsence: null,
      groupe: null
    });
  };

  return (
    <SlidePanel open={open} setOpen={setOpen}>
      <div className="presences-filters">
        <div className="filters-header">
          <h2 className="title">{t('presences.filtres.titre')}</h2>
          <div className="close-button btn btn-no-style" onClick={() => setOpen(false)}>
            x {t('global.fermer')}
          </div>
        </div>
        <div className="filters">
          <AgenceFilter />
          {selectedAgence && <AreaFilter />}

          <div>
            <h3>{t('presences.filtres.parPersonne')}</h3>
            <Select
              options={users}
              onChange={users => setUsersFilter(users as UserDto[])}
              placeholder={t('presences.filtres.utilisateurs')}
              value={selectedUsers}
              isMulti
              getOptionLabel={u => u.name}
              getOptionValue={u => u.guid as string} />
          </div>

          <div>
            <h3>{t('presences.filtres.parGroupe')}</h3>
            <Select
              options={groupeOptions}
              onChange={groupeOption => selectGroupe(groupeOption as SelectOption<Groupe>)}
              placeholder={t('presences.filtres.groupe')}
              value={selectedGroupe}
              isSearchable={false}
              isClearable />
          </div>
        </div>

        <div className="actions">
          <button className="btn btn-tertiary" onClick={reinitFilters}>{t('presences.filtres.reinit')}</button>
        </div>
      </div>
    </SlidePanel>
  );
};
